import React from "react";
import "./Logo.css";

function Logo() {
    return (
    <div>
        <img className="logo" src="./mongoosecode.png" />
    </div>
    );
}

export default Logo;