import React from "react";
import ReactDOM from "react-dom";
import Preloader from "./components/Preloader/Preloader";
import Logo from "./components/Logo/Logo";

import './App.css';

function App() {
  return (
    <div className="App">
      <div className="container">
        <Logo />
        <h1>
          Something great
          <br />
          is coming soon
        </h1>
        <Preloader />
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);


export default App;
